import * as React from "react";
import JourneyImage from "../../components/JourneyImage";
import JourneyPageLayout, {
  CHAPTERS,
} from "../../components/JourneyPageLayout";
import blackFridayImage from "../../images/journey/black_friday.jpg";
import blackFridayWarRoomImage from "../../images/journey/black_friday_warroom.jpg";
import bradImage from "../../images/journey/brad.jpg";
import devTeam1Image from "../../images/journey/dev_team1.jpg";
import devTeam2Image from "../../images/journey/dev_team2.jpg";
import legoWallImage from "../../images/journey/lego_wall.jpg";
import newSuperbalistOfficeImage from "../../images/journey/new_superbalist_office.jpg";
import superbalistCakeImage from "../../images/journey/superbalist_cake.jpg";
import takealotAcquisitionImage1 from "../../images/journey/takealot_acquisition1.jpg";
import takealotAcquisitionImage2 from "../../images/journey/takealot_acquisition2.jpg";
import takealotWarehouseImage from "../../images/journey/tal_warehouse.jpg";
import ogImage from "../../images/og/journey-superbalist.jpg";

const JourneySuperbalistPage = () => {
  return (
    <JourneyPageLayout
      pageTitle="Matthew Goslett - How We Built Superbalist"
      metaDescription="Matthew Goslett shares his experience as both a co-founder and former Chief Technical Officer of Superbalist.com."
      og={{ image: ogImage }}
      chapter={CHAPTERS.SUPERBALIST}
    >
      <p>
        The launch date was set for November 4th, giving us 3 months to prepare
        for the big re-launch. The marketing team worked on the rebrand, buyers
        and planners were sourcing new products, and the engineering team
        started the mammoth task of building yet another website. The
        engineering team at the time consisted of Neil Garb (formerly of
        takealot.com), Nic Appleby (formerly of TouchLab/Media24), and myself.
        Luke Jedeikin (Managing Director) led the design process together with
        our UX designer Joëlle Snaith, and the frontend was outsourced to
        Mauricio Balderrama, a Bolivian contractor whom we'd developed a
        relationship with over the years.
      </p>
      <JourneyImage image={devTeam1Image} />
      <p>
        I'd started reading up on this PHP framework called Laravel, which had
        just released their 4th, and arguably first, production ready version. I
        played around with it over a weekend and built a small prototype app,
        testing the feasibility of its use for this project. The team had no
        prior experience with it, but we liked what we saw and unanimously
        agreed to move forward with it. The 3 of us modeled the database
        together, much of which is still in use today. The rest of the project
        took the "just do it" approach - we contributed and coded wherever we
        were needed. Once the application started to take shape, we developed
        unofficial roles for the core components - API (Neil), CMS (Nic), and
        WWW (myself).
      </p>
      <p>
        As we neared the launch date, we all started to struggle with the stress
        and pressure of meeting the deadline. We'd been living off fast food,
        energy drinks, and medicine for 3 months. A week before launch, I broke
        down mentally at the office in a state of delirium. I remember it like
        it was yesterday; walking up to Luke, Claude, and Mikael's desks -
        shaking, incoherent, and stuttering. I left my car at the office and was
        given a lift home. A doctor made a house call, and I spent the next week
        in bed recovering from mental exhaustion. It was a bittersweet week for
        me since we weren't able to shift the deadline out and the rest of the
        team had to keep on pushing through. I contributed what I could from
        home, but both Nic and Neil picked up the pieces and pulled us through
        to launch day.
      </p>
      <p>
        In the early hours of the morning on November 4th, the engineering team
        flipped the switch and pushed Superbalist.com live.
      </p>
      <JourneyImage image={superbalistCakeImage} />
      <p>
        It was such a momentous occasion for everyone, and I couldn't be more
        proud of and grateful to the guys for pulling off what they did. I
        unfortunately missed the cake and celebrations but returned to work
        shortly after launch. As any developer knows, launch is just day 1 of
        phase 2, and the next few months were spent firefighting bugs and
        implementing "todo" and "fixme" notes.
      </p>
      <p>
        In Feb 2014, the team took a small knock when Neil announced he was
        moving on from e-commerce. Neil had brought a wealth of experience with
        him from takealot and his many years as a developer, and I'm thankful to
        him for introducing me to many of the academic design practices and
        principles I know today. A few months after his departure, we were
        joined by Shaun Michaels, who had recently graduated from UCT with a
        Bachelor of Business Science majoring in Computer Science.
      </p>
      <JourneyImage image={devTeam2Image} />
      <p>
        The 2013/2014 years were significant for the company. We were growing
        exponentially month after month and needed to seek investment to further
        fund our expansion and inventory holdings. We could no longer continue
        to fund the company ourselves, so we started exploring outside
        investment. We met with one of the largest internet and entertainment
        groups based in South Africa, who had expressed interest in us. A
        grueling 9-month due diligence followed, but at the 11th hour, the deal
        was pulled after the investors announced a change in strategy focused on
        consolidating their e-commerce companies rather than expanding. It was a
        huge emotional blow and setback for the executive team, as we were
        forced to restart our search for funding. A 2nd potential investment
        opportunity with a UK-based technology-centric venture capitalist
        followed shortly thereafter, but failed to materialise after a 6-month
        due diligence.
      </p>
      <p>
        In early 2014, we met with Kim Reid (CEO of takealot.com) and started
        early discussions with the group about investment opportunities. A 3rd
        due diligence followed, and on August 21st, we met at Cliffe Dekker
        Hofmeyr Attorneys in Cape Town to conclude the deal. The takealot.com
        group, who at the time already owned Mr Delivery (now Mr D Food and
        Takealot Delivery Team), acquired 100% ownership stake in
        Superbalist.com.
      </p>
      <p>
        <span className="font-italic">
          “The millennial generation is deemed to be the most powerful and
          relevant market on the planet and we have managed to acquire a
          business run by millennials, focused on millennials. They have a great
          team and we are going to have fun building the business together.”
        </span>
        - Kim Reid
      </p>
      <JourneyImage image={takealotAcquisitionImage1} />
      <p>
        The business continued to operate independently as a division of
        takealot.com with the existing management team still in place. The
        acquisition allowed us to accelerate our growth in the South African
        market. The additional funding and group resources opened up access to
        new marketing channels, such as television and radio, improved logistics
        via the Takealot Delivery team, and warehouses in both Cape Town and
        Johannesburg (2 major centres).
      </p>
      <JourneyImage image={takealotAcquisitionImage2} />
      <p>
        In early March 2015, my role as Chief Technology Officer (CTO) came to
        an end. I was suffering from many personal issues at the time, including
        anxiety and substance abuse. I'm incredibly open about my struggle with
        addiction and don't feel ashamed to speak openly about it. I did what I
        thought was best for myself, and only myself, and self-admitted to a
        treatment facility. I needed to focus on my personal well-being and
        couldn't serve the position in a way which was best for the company.
      </p>
      <p>
        It was an evening visitor session when Claude came to see me at the
        facility. We were sitting in the garden area having a cup of tea, when
        he opened up a discussion about me taking a step back and allowing us to
        bring someone on with more experience. I remember being receptive to the
        suggestion, not shocked at all. I knew there was no way I could return
        to work as if nothing had happened, nor could I prioritise work over
        myself without jeopardising one or the other. It was still a difficult
        and emotional reality to process. It was the end of one journey, and
        certainly not in the way I wanted nor in a way I would ever have
        envisioned.
      </p>
      <p>
        I experienced many mixed emotions upon my return to work. I felt
        incredibly ashamed at the time and was concerned about how my colleagues
        might judge me. There were rumours circulating about my sudden
        disappearance, and I felt like many people were treading on eggshells
        around me. The one person I was truly comfortable opening up to was the
        company's new Human Resource Manager - Bailey Kropman. Bailey, an
        organisational psychologist, had only been at the company for give or
        take a month before she was tasked with facing my dilemma. I'm truly
        grateful to her for the way she dealt with everything, both
        professionally and personally. It took a while before the tension eased
        up and I felt more comfortable in the work environment. There was lots
        of anger, resentment, and distrust between Claude, Luke, Mikael, and
        myself, and even today, I feel like things still haven't recovered to
        how they once were. It would be at least a year before Luke and I would
        speak again.
      </p>
      <JourneyImage image={bradImage} />
      <p>
        A few months after I returned to work, we hired Brad Whittington to take
        over my position. Brad had many years of experience as both an engineer
        and manager and had formerly served as CTO of Mxit, the instant
        messaging platform which, at its peak, had 7.4 million monthly active
        users. I stepped down and took on the position of Lead Developer,
        handing over my managerial responsibilities to Brad. I moved into a
        somewhat R&D and experimental type engineering role and worked on
        projects such as search, platform/devops, event infrastructure, and new
        payment integrations. It was a role which I felt I was well-suited for,
        since I loved (and still do love) playing around with experimental tech,
        learning new languages, and running solo with big project builds.
      </p>
      <p>
        Towards the end of 2015, we once again moved offices, this time to the
        Atlantic Centre in the Cape Town Foreshore (takealot.com building). The
        rest of the group had recently moved from there to Rua Vasco Da Gama
        Plain. This would be the new home for all group companies; however, we
        took over the existing Atlantic Centre lease whilst renovations were
        being made to our floor.
      </p>
      <JourneyImage image={newSuperbalistOfficeImage} />
      <p>
        The mammoth warehouse move followed shortly thereafter. At the time, we
        had a single warehouse up in Johannesburg, the Parcel Ninja warehouse.
        This was the first time we'd introduced multiple distribution centres
        into the business (and tech systems). Nic managed the project from a
        technical perspective, re-writing the logistics engine to incorporate Mr
        D as well as the new warehouse management software. Gary Krige assisted
        him and wrote the "reservations engine" - the service responsible for
        reserving your stock at a warehouse when you click the "add to cart"
        button on the website.
      </p>
      <JourneyImage image={takealotWarehouseImage} />
      <p>
        The company continued on its upward trajectory in 2016 and we started to
        face real tech scaling issues. The platform team, led by William
        Stewart, migrated us to Kubernetes, hosted on the Google Cloud Platform.
        I spent a lot of time breaking up our monorepo into smaller, installable
        packages and containerised services. In preparation for Black Friday
        that year, we deep profiled our core services and refactored and
        optimised significant portions of our code right up until the night
        before. It paid off, and we survived Black Friday relatively unscathed.
        It would be my last Black Friday at the company, and still today, that’s
        one of the things I miss most about working in the industry. The thrill
        of firefighting, war rooms, graphs and dashboards, company-wide synergy,
        record-breaking traffic and volumes.
      </p>
      <JourneyImage image={[blackFridayWarRoomImage, blackFridayImage]} />
      <p>
        2017 (7 years since we started) was when I really started to struggle at
        the company. The passion I once felt was no longer there. I wasn't
        particularly excited nor challenged by the work I was doing. I loved the
        team, and learning from my colleagues, but the company no longer had
        that start-up culture I was looking for. This is inherent with any
        company experiencing the type of growth we were - it had outgrown me.
        I'd felt this uncertainty for quite some time but had been afraid to let
        go. The company had been such a significant part of my professional
        life. It was my first full-time job, and I had no idea how things would
        be elsewhere. I was afraid I'd never be able to build the type of bonds
        I had built, or find a company which would invoke those same feelings I
        experienced, during the early days.
      </p>
      <p>
        In early 2017, I started exploring other options and contacted a friend
        of mine who runs a recruitment agency. They presented an opportunity at
        an education start-up which was looking for someone to head up their
        tech. I was excited by the challenge and was fond of working with this
        group of investors, all of whom had many years of experience in digital
        and a track record of incubating ideas and creating successful
        companies. I was sold early on, and shortly thereafter I accepted an
        offer for a CTO position at MasterStart.
      </p>
      <JourneyImage image={legoWallImage} />
      <p>
        I took this photo on my way out of the office on my final day. I'm still
        in disbelief of how we managed to turn this little Pepper Street
        start-up into the company it is today. I've often thought back and
        wondered what my life would be like if I hadn't seen and responded to
        that tweet from Skye 7 years earlier. The memories we created together,
        the friendships we formed, the challenges we faced and overcame - these
        are things I'll never forget.
      </p>
      <p>
        <span className="font-italic">Au revoir Superbalist!</span>
      </p>
      <div className="mb-3">
        <div className="embed-responsive embed-responsive-16by9 mb-3">
          <iframe
            className="embed-responsive-item"
            src="https://www.youtube.com/embed/PufO9WJc_pQ"
            allowFullScreen
          />
        </div>
      </div>
    </JourneyPageLayout>
  );
};

export default JourneySuperbalistPage;
